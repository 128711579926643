<script>
import Loading from '@/components/general/Loading.vue'
export default {
  components: { Loading },
  name: 'RegisterRedirect',
  computed: {
    isEnterprise () {
      return this.$route.params.type === 'enterprise'
    }
  },
  created () {
    this.clearStorageAndLogout()
  }
}
</script>
<template>
  <Loading class="loading-wrapper" :class="{'is-enterprise': isEnterprise}" :size="70" :width="7" color="white"/>
</template>
<style lang="scss">
.circular-loading {
  z-index: 101;
  top: 40%;
}
.loading-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color:$primary-medium;
  z-index: 101;
  overflow: visible;
}
.is-enterprise {
  background-color: $secondary-medium !important;
}
</style>
